/* You can add global styles to this file, and also import other style files */
//@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~@ng-select/ng-select/themes/material.theme.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


mat-toolbar{
  background-color: white !important;
  min-height: 70px;
  box-shadow: 0 10px 30px 0 rgba(47, 60, 74, 0.08);
  z-index: 1001;
  position: sticky;
  top: 0px;
}
.mat-drawer-side{
  background: #222a45 !important;
  color: #ffffff !important;
  width: 250px;
}
.mat-elevation-z8{
  box-shadow: none !important;
  border-collapse: collapse;
}
.title{
  font-size: 22px !important;
    font-weight: 500 !important;
    color: #3f51b5;
}
.example-full-width{
  width: 100%;
}
.mat-column-action{
  width: 20%;
}
.custom-upload:hover{
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.nowrap{
text-wrap: nowrap !important;
}
.custom-upload{
  cursor: pointer;
  background-color: #3f51b5;
  color: #fff;
  //transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 8px 16px 8px 16px;
  height: 37px;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: 500;
 // margin-bottom: 0.5rem!important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}
.filter-btn{
  position: relative;
}
.icon-dot{
  height: 10px;
  width: 10px;
  position: absolute;
  border-radius: 50%;
  top: -20px;
  right: -25px;
 background-color: red;
}
.active-filter{
  visibility: hidden;
}
.close-card-btn{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.table-wrapper{
  overflow-x: auto;
  width: 100%;
}
.right-list{
  display: flex;
  justify-content: end;
}
.custom-dialog-class-p{
width: 600px;
}
@media (max-width:768px) {
  .custom-dialog-class{
    width: 95% !important;
    max-width: 100vw !important;
  }
  .custom-dialog-detail-class{
    width: 95% !important;
    max-width: 100vw !important;
  }
  .mdc-dialog .mdc-dialog__content{
    padding: 20px !important;
  }
  .right-list{
    display: inline;
    justify-content: end;
  }
}

.custom-search{
  display: flex;
  position: relative;
  .search-icon{
  position: absolute;
  top: 8px;
  left: 5px;}
  .form-control{
 padding-left: 35px;
  }
  .form-control::placeholder{
  font-weight: 500;
  }
  .form-control:focus{
  box-shadow: none;
  }
}
.mat-mdc-form-field-required-marker{
  display: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}